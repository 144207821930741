import React, { FC, Fragment } from "react";


const NotFoundPage: FC = () => {
  return (
    <Fragment>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&apos;t exist...</p>
    </Fragment>
  );
};

export default NotFoundPage;
